import { useState } from "react";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
  addDays,
  isSameMonth,
  addMonths,
  subMonths,
  isSameDay,
} from "date-fns";
import "./styles.css";
import { Project, ProjectStatus } from "../../../types/project";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ClockIcon from "@mui/icons-material/Timer";
const MonthlyCalendar = ({ data }: { data: Project[] | undefined }) => {
  const navigate = useNavigate();
  const dateFormat = "MMMM yyyy";
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedJob, setSelectedJob] = useState(-1);

  const changeMonthHandle = (btnType: "prev" | "next") => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  //   const onDateClickHandle = (day: any) => {
  //     setSelectedDate(day);
  //     // showDetailsHandle(dayStr);
  //   };

  const getTodaysjobs = (todaysDate: Date): Project[] => {
    const list = data?.filter((project: Project) => {
      const startDate = dayjs(project.start_date);
      const projectDate = startDate.get("date");
      const projectYear = startDate.get("year");
      const projectMonth = startDate.get("month");
      return (
        (project.status === ProjectStatus.SCHEDULED ||
          project.status === ProjectStatus.IN_PROGRESS ||
          project.status === ProjectStatus.PUBLISHED) &&
        todaysDate.getDate() == projectDate &&
        todaysDate.getMonth() == projectMonth &&
        todaysDate.getFullYear() == projectYear
      );
    });
    return list || [];
  };

  function renderHeader() {
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }

  function renderDays() {
    const dateFormat = "EEEE";
    const days = [];

    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  function renderJobs(day: Date) {
    return getTodaysjobs(day).map((project) => {
      let bgColor;
      if (project.status === ProjectStatus.PUBLISHED) {
        bgColor = "#0288D1";
      } else if (project.status === ProjectStatus.SCHEDULED) {
        bgColor = "#ED6C02";
      } else if (project.status === ProjectStatus.IN_PROGRESS) {
        bgColor = "#ED6C02";
      }
      if (selectedJob === project.id) {
        return (
          <div
            style={{
              top: 24,
              width: "100%",
              cursor: "pointer",
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 8,
              paddingRight: 8,
              margin: 2,
              borderRadius: 8,
              backgroundColor: bgColor,
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 14,
                fontWeight: "500",
                flexDirection: "row",
                display: "flex",
              }}
              onClick={() => {
                // if (selectedJob === project.id) {
                //   setSelectedJob(-1);
                // } else {
                //   setSelectedJob(project.id);
                // }
              }}
            >
              <ClockIcon />
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  lineHeight: 1.8,
                  marginLeft: 4,
                }}
              >
                {dayjs(project.start_date).format("hh mm A z")}
              </div>
            </div>
            <div
              onClick={() => {
                if (selectedJob === project.id) {
                  setSelectedJob(-1);
                } else {
                  setSelectedJob(project.id);
                }
              }}
            >
              {project.title}
            </div>
            {selectedJob === project.id && (
              <div
                style={{ fontWeight: 800, fontSize: 14, marginTop: 8 }}
                onClick={() => navigate(`/works/${project?.id}`)}
              >
                {`Go To Detail >>`}
              </div>
            )}
          </div>
        );
      }
      return (
        <div
          style={{
            zIndex: 99,
            cursor: "pointer",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            margin: 2,
            borderRadius: 8,
            backgroundColor: bgColor,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            if (selectedJob === project.id) {
              setSelectedJob(-1);
            } else {
              setSelectedJob(project.id);
            }
          }}
        >
          {project.title}
        </div>
      );
    });
  }

  function renderCells() {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart) ? "disabled" : ""
            }${isSameDay(day, new Date()) ? "today" : ""}`}
            key={day.toString()}
          >
            <span className="number">{formattedDate}</span>
            <Box sx={{ height: 24 }} />
            {/* <span className="bg">{formattedDate}</span> */}
            {renderJobs(day)}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  function nextMonth() {
    setCurrentMonth(addMonths(currentMonth, 1));
  }

  function prevMonth() {
    setCurrentMonth(subMonths(currentMonth, 1));
  }
  //   const renderFooter = () => {
  //     return (
  //       <div className="header row flex-middle">
  //         <div className="col col-start">
  //           <div className="icon" onClick={() => changeWeekHandle("prev")}>
  //             prev week
  //           </div>
  //         </div>
  //         <div>{currentWeek}</div>
  //         <div className="col col-end" onClick={() => changeWeekHandle("next")}>
  //           <div className="icon">next week</div>
  //         </div>
  //       </div>
  //     );
  //   };
  return (
    <div className="monthly_calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default MonthlyCalendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
